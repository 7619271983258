import React from "react"
import { Link } from "react-router-dom"

function NotFound() {
    return (
        <div className="container py-5 container_box">
            <div className="row justify-content-center text-center">
                <h2>Whoops, we cannot find that page.</h2>
                <p className="lead text-muted">
                    You can always visit the <Link to="/">homepage</Link> to get a fresh start.
                </p>
            </div>
        </div>
    )
}

export default NotFound
