import React from 'react';

const RecyclingClothes = () => {

    return (
        <div className="container py-5 container_box">
            <div className="row justify-content-center">
                <h2 className='text-center'>Why start a Clothing Drive?
                </h2>
                <p>
                    The clothes we wear carry more than our individual style; they bear the imprint of an industry with a profound impact on our environment. An impact that's often invisible as we admire the latest styles and trends lining up the store shelves.
                </p>
                <p>
                    The fashion industry contributes to approximately 10% of global greenhouse gas emissions, with fast fashion brands leading the charge. That's more than the emissions from all the international flights and shipping combined! The production of a single cotton shirt requires about 2,700 liters of water - enough for one person to drink for 2.5 years <a target='_blank' href="https://www.unenvironment.org/news-and-stories/story/putting-brakes-fast-fashion">[source]</a>. Another problem with fast fashion has to do with textile waste. We are more likely to throw away cheap and trendy clothes rather than expensive and timeless pieces. According to the council for Textile Recycling, the average American throws away about 70 pounds of clothing and other textiles each year. Each second, the equivalent of one garbage truck of textiles is landfilled or burned. Washing clothes releases half a million tonnes of microfibers into the ocean each year, the equivalent of more than 50 billion plastic bottles.
                </p>
                <p>
                    Further, the industry contributes to deforestation due to the production of cellulose fibers like rayon and modal, derived from wood pulp. Over 150 million trees are logged each year for this purpose. The overuse of chemicals in cotton farming leads to soil degradation, biodiversity loss, while energy-intensive production processes exacerbate the global climate crisis. It is clear that a fundamental shift in our clothing consumption patterns is necessary.

                </p>
                <p>
                    As consumers, we have the power to rewrite this narrative. Choosing clothing made from sustainable fibers, supporting brands with ethical production practices, opting for quality over quantity, and advocating for regulations enforcing sustainability are some ways we can contribute. One impactful way to bring about change is by participating in or organizing clothing drives. These drives have both local and global impacts, providing immediate benefits to recipients while fostering a larger culture of reuse and sustainability.

                </p>
                <p>
                    Through clothing drives, we can make a remarkable difference. They reduce waste, keeping tons of textiles out of landfills, thus giving them a second life. They conserve resources by reducing the demand for new clothing production. They also provide an opportunity for community awareness and education about sustainable fashion and conscious consumption.
                </p>
                <p>
                    Clothing drives signify the power of collective action. Participating in a clothing drive, you're not just donating clothes; you're contributing to a global movement addressing environmental and humanitarian needs. Let's donate, recycle, reuse, and shift towards sustainable fashion.

                </p>
            </div>
        </div>
    );
};

export default RecyclingClothes;
