import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
//import firebase from '../src/firebase';

import AuthForm from './components/AuthForm';
import PrivateRoute from './components/privateRoute'
import HomePage from './components/homePage';
import DriveList from './components/driveLists';
import FAQ from './pages/faq';
import DriveForm from './components/driveForm';
import HeaderNav from './components/headerNav'
import DriveDetails from './components/driveDetails';
//import ServicePage from './components/servicePage';
import Footer from './components/footer';
import ForgotPassword from './components/forgotPassword';
import MyDriveList from './components/myDriveLists';
import AboutClimate from './pages/aboutClimate';
import FoodInsecurity from './pages/foodInsecurity';
import RecyclingClothes from './pages/recyclingClothes';
import WhyDonate from './pages/whyDonate';
import NotFound from "./pages/pageNotFound"
import OrganizeADrive from "./pages/organizeADrive"
import DiscoverLocalCharities from "./pages/discoverLocalCharities"
import './App.css';


function App() {

  //console.log(firebase.auth().currentUser);

  return (
    <BrowserRouter>
      <HeaderNav />
      <Routes>

        <Route path="*" element={<NotFound />} />

        <Route path='/drives-booking/' element={<PrivateRoute />}>
          <Route path='/drives-booking/' element={<DriveForm />} />
        </Route>

        <Route path='/drives-booking/:tblId' element={<PrivateRoute />}>
          <Route path='/drives-booking/:tblId' element={<DriveForm />} />
        </Route>

        {/* <Route path='/drive-list/' element={<PrivateRoute />}>
          <Route path='/drive-list/' element={<DriveList />} />
        </Route> */}
        {/* 
        <Route path='/drive-detail/:id' element={<PrivateRoute />}>
          <Route path='/drive-detail/:id' element={<DriveDetails />} />
        </Route> */}

        <Route path='/my-drives/' element={<PrivateRoute />}>
          <Route path='/my-drives/' element={<MyDriveList />} />
        </Route>

        <Route path='/drive-list/' element={<DriveList />} />
        <Route path='/drive-detail/:id' element={<DriveDetails />} />
        <Route path='/sign-up' element={<AuthForm />} />
        <Route path='/login' element={<AuthForm />} />

        <Route path='/faq' element={<FAQ />} />

        <Route path='/' element={<HomePage />} />

        <Route path='/about-climate' element={<AboutClimate />} />
        <Route path='/food-insecurity' element={<FoodInsecurity />} />
        <Route path='/recycling-clothes' element={<RecyclingClothes />} />
        <Route path='/how-to-organize-a-drive' element={<OrganizeADrive />} />
        <Route path='/discover-local-charities' element={<DiscoverLocalCharities />} />
        <Route path='/why-donate' element={<WhyDonate />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;


