import React from 'react';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import logo from "../assets/images/drive_logo.png"


import { Navbar, Nav, NavDropdown, Row, Col, Container, Button } from 'react-bootstrap';

const HeaderNav = () => {
    const navigate = useNavigate();

    //const [isLogin, setIsLogin] = useState(false);
    const auth = getAuth();
    //console.log(auth);
    // useEffect(() => {
    //     console.log(auth.currentUser);
    //     if(auth.currentUser){
    //         setIsLogin(true) 
    //     } else{
    //         setIsLogin(false)
    //     }

    // }, [auth]);


    const handleSignOut = () => {
        //firebase.auth().signOut();       
        signOut(auth);
        navigate('/')
        window.location.reload(true);

        // You can perform any additional actions after sign-out if needed
    };

    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Row className="width-100">
                    <Col md={1}>
                        <Link className='text-decoration' to="/"><img className="app_logo" src={logo} alt=""></img></Link>
                    </Col>
                    <Col md={5}>
                        <h2 className='mt-10'>Charity Drives</h2>
                        {/* <Navbar.Brand href="/home">Site Name</Navbar.Brand> */}
                    </Col>
                    <Col md={6}>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <Nav className="mr-auto">
                                <NavDropdown title="Learn" id="basic-nav-dropdown">
                                    <NavDropdown.Item><Link className='text-decoration' to="/why-donate">Why Donate?</Link></NavDropdown.Item>
                                    <NavDropdown.Item><Link className='text-decoration' to="/about-climate">About Climate Justice</Link></NavDropdown.Item>
                                    <NavDropdown.Item><Link className='text-decoration' to="/food-insecurity">Food Insecurity</Link></NavDropdown.Item>
                                    <NavDropdown.Item><Link className='text-decoration' to="/recycling-clothes">Recycling Clothes</Link></NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Organize" id="basic-nav-dropdown">
                                    <NavDropdown.Item><Link className='text-decoration' to="/drives-booking">Create an Event</Link></NavDropdown.Item>
                                    <NavDropdown.Item><Link className='text-decoration' to="/how-to-organize-a-drive">How to Organize a Drive</Link></NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Find" id="basic-nav-dropdown">
                                    <NavDropdown.Item><Link className='text-decoration' to="/drive-list">Charity Events</Link></NavDropdown.Item>
                                    {/* <NavDropdown.Item><Link className='text-decoration disabled-color' disabled={true} to="/">Discover Local Charities</Link></NavDropdown.Item> */}
                                </NavDropdown>
                                {auth.currentUser &&
                                    <Button onClick={handleSignOut} variant="primary">Log Out</Button>
                                }
                                {auth.currentUser === null &&
                                    <Button variant="primary" href="/login">Sign In</Button>
                                }

                            </Nav>
                        </Navbar.Collapse>
                    </Col>
                </Row>
            </Container>
        </Navbar>

    );

};

export default HeaderNav;
