import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { db } from '../firebase';
import { formatWithMonthNameOnly } from "./utils"
//import Map from "./map"
import { formatWithMonthName } from "./utils"
import loader from '../assets/images/icons8-dots-loading.gif'

const DriveDetails = () => {
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    useEffect(() => {
        // Fetch data from Firestore
        const fetchData = async () => {
            setLoading(true)
            const snapshot = await db.collection('driveData')
                .where('driveId', '==', id)
                .where('isActive', '==', true)
                .get();
            const data = snapshot.docs.map((doc) => doc.data());
            //console.log(data);
            setDataList(data);
            setLoading(false)
        };

        fetchData();
    }, [id]);

    console.log(dataList);

    const loader_img = <img className="img-fluid" src={`${loader}`} alt="loader" />
    // const location = 'USA';
    // const encodedAddress = encodeURIComponent(dataItem.driveLocation);
    // const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=New%20Delhi&zoom=13&scale=2&size=600x300&maptype=roadmap&format=png&key=${process.env.REACT_APP_GOOGLEAPIKEY}`;

    return (
        <div className="container py-5 container_box">
            <div className="row">
                <div className="col-md-5 ">
                    <div className="dev-left">
                        <h2 className='text-center'>Event Details</h2>
                    </div>
                </div>

                <div className="col-md-5 ">
                    <div className="dev-right">
                        <p className='drive_list_link'><Link to='/drive-list'>Back to list</Link></p>
                    </div>
                </div>

            </div>

            <div className="row justify-content-center">
                <div className='text-center mt-3'>{loading ? loader_img : ''}</div>
                {dataList.length === 0 && !loading &&
                    <div className='text-center mt-3'>No data available!</div>
                }
                {dataList.map((dataItem, index) => (
                    <div className="col-md-10" key={index}>
                        <div className="card mb-4">
                            <div className="card-body">
                                {dataItem.dateTimeList['0']['date'].length > 0 && (
                                    <div className="card-text">

                                        <strong>Date Time: </strong>
                                        <div className="row" style={{ color: '#fff' }}>
                                            {/* {typeof dataItem.dateTimeList === 'undefined' &&
                                            <div className="col-md-4 mb-4">
                                                <div className="card position_relative" style={{ backgroundColor: '#' + Math.random().toString(16).substr(-6) }}>
                                                    <div className="card-body position_relative height_140">
                                                        <div className="card-overlay">
                                                            <strong>Slot Not Available</strong>
                                                            <p className="card-text">
                                                                Date: N/A <br /> Start Time: N/A <br /> End Time: N/A</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } */}
                                            {dataItem.dateTimeList && dataItem.dateTimeList.map((dateTime, index) => (
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="card position_relative" style={{ backgroundColor: '#' + Math.random().toString(16).substr(-6) }}>
                                                        <div className="card-body position_relative height_140">
                                                            <div className="card-overlay">
                                                                <strong>Slot {index + 1}</strong>
                                                                <p className="card-text">
                                                                    Date: {formatWithMonthNameOnly(dateTime.date)} <br /> Start Time: {dateTime.startTime ? dateTime.startTime : 'N/A'} <br /> End Time: {dateTime.endTime ? dateTime.endTime : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                // <span>{dateTime.date} &nbsp; {dateTime.time} &nbsp; &nbsp;</span>
                                            ))}
                                        </div>

                                    </div>
                                )}
                                <div className='map'>
                                    <img alt="map" width="100%" src={`https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(dataItem.driveLocation)}&zoom=13&scale=2&size=600x300&maptype=roadmap&format=png&key=${process.env.REACT_APP_GOOGLEAPIKEY}`} />
                                </div>
                                <p className="card-text">
                                    <strong>Event Name: </strong>{dataItem.driveName}
                                </p>
                                <p className="card-text">
                                    <strong>Event Subtitle:</strong> {dataItem.driveSubtitle ? dataItem.driveSubtitle : 'N/A'}
                                </p>
                                <p className="card-text">
                                    <strong>Location:</strong> {dataItem.driveLocation ? dataItem.driveLocation : 'N/A'}

                                </p>

                                <p className="card-text">
                                    <strong>Description:</strong> {dataItem.driveDescription ? dataItem.driveDescription : 'N/A'}
                                </p>

                                <p className="card-text">
                                    <strong>Event Purpose:</strong> {dataItem.drivePurpose ? dataItem.drivePurpose : 'N/A'}
                                </p>

                                <p className="card-text">
                                    <strong>Organization Name:</strong> {dataItem.organizationName ? dataItem.organizationName : 'N/A'}
                                </p>


                                <p className="card-text">
                                    <strong>Affliation Name:</strong> {dataItem.affliationName ? dataItem.affliationName : 'N/A'}
                                </p>


                                <p className="card-text">
                                    <strong>Contact Info:</strong> {dataItem.contactInfo ? dataItem.contactInfo : 'N/A'}
                                </p>

                                <p className="card-text">
                                    <strong>Created On:</strong> {formatWithMonthName(dataItem.createdOn)}
                                </p>


                            </div>
                        </div>
                    </div>
                ))}
            </div>





            {/* <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Event Name</th>
                                        <th>Event Purpose</th>
                                        <th>Event Location</th>
                                        <th>Event Description</th>
                                        <th>Event dateTime</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList && dataList.length > 0 ? (
                                        dataList.map((dataItem, index) => (
                                            <tr key={index}>
                                                <td>{dataItem.driveName}</td>
                                                <td>{dataItem.drivePurpose}</td>
                                                <td>{dataItem.driveLocation}</td>
                                                <td>{dataItem.driveDescription}</td>
                                                <td>
                                                    {dataItem.dateTimeList && dataItem.dateTimeList.map((dateTime, index) => (
                                                        <span>{dateTime.date} &nbsp; {dateTime.time} &nbsp; &nbsp;</span>
                                                    ))}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className="text-center border-bottom-width">
                                                <div className='text-center mt-3'>{loading ? loader_img : 'No data available'}</div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> */}
        </div >
    );
};

export default DriveDetails;
