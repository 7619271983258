import React from 'react';
//import GoogleSearch from '../components/googleSearch';

const DiscoverLocalCharities = () => {

    return (
        <div className="container py-5 container_box">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body text-center">
                            <h2 className='text-center'>Discover Local Charities</h2>

                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            {/* <GoogleSearch /> */}


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscoverLocalCharities;
