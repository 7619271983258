import React from 'react';

const AboutClimate = () => {

    return (
        <div className="container py-5 container_box">
            <div className="row justify-content-center">
                <h2 className='text-center'>About Climate Justice</h2>
                <p>
                    You may wonder, what do food drives and clothing drives have to do with climate change and climate justice?

                </p>
                <p>
                    Let's start with what is climate justice?

                </p>
                <p>
                    According to the <a href="https://www.mrfcj.org/climate-justice/" target='_blank'>Mary Robinson Foundation</a> - Climate Justice: "Climate Justice links human rights and development to achieve a human-centered approach, safeguarding the rights of the most vulnerable people and sharing the burdens and benefits of climate change and its impacts equitably and fairly." The US <a target='_blank' href="https://www.epa.gov/environmentaljustice">Environmental Protection Agency</a> (EPA) defines environmental justice as "the fair treatment and meaningful involvement of all people regardless of race, color, national origin, or income, with respect to the development, implementation, and enforcement of environmental laws, regulations, and policies." While these two terms have different emphases, they overlap significantly in the issues they address and the goals they pursue. Both prioritize the fair and equitable treatment of all people, particularly those most vulnerable, in relation to environmental policies, impacts, and decision-making processes and emphasize the fact that climate change has a greater impact on those living in poverty and the least responsible for greenhouse gas emissions. The concept of climate justice argues that those who are least responsible for climate change suffer its gravest consequences and calls for a more equitable distribution of the costs of these impacts, globally and domestically.

                </p>
                <p>
                    As we know, global warming induced climate change is leading to frequent extreme weather events happening all over the world. Excessive rainfall and floods, record high and record low temperatures, droughts, forest fires and intense hurricanes often have a severe impact on our lives, ecosystems, property, infrastructure, and economy. These events disproportionately affect marginalized groups. The affected people struggle to get food, clothing, supplies, clean water and shelter. So, climate change is a climate justice issue because the impact of such events is felt far more by poor people and poor countries. Over a longer term period, such climate change events lead to a decline in food production which in turn leads to food scarcity, rising costs and food insecurity for the poor people. For example, in California, high temperatures, droughts and wildfires have affected agricultural productivity and increased food insecurity and homelessness.
                </p>
                <p>
                    According to the World Bank, in the next 15 years or so, climate change could force an additional 100 million people into extreme poverty. In addition, by 2050, the International Food Policy Research institute estimates a 20% increase in malnourished children compared to what we would see without climate change. The poor will become poorer and the people most impacted by climate change are those with fewer resources to defend themselves. The ill effects of climate change-induced extreme weather events will continue to be felt far more by the poor and vulnerable populations across the world and in particular in poorer nations. Climate injustice also intersects with social injustice and economic injustice. In order to address climate injustice, we must acknowledge and address these other forms of injustices as well.

                </p>
                <p>
                    It is also important to understand that not everyone has contributed to climate change in the same way. The world’s richest 10% are responsible for 50% of GHG emissions and the poorest 50% are only responsible for 10% despite population and energy consumption increasing. But the adverse effects of the climate crisis are felt the most by the people who have the least role in contributing to it. Last year, the United Nations (during its annual climate conference COP27), announced a "loss and damage" fund to support poor nations impacted by climate change-induced extreme weather events. This is a good start for climate justice, but more needs to be done. We can do our part by supporting communities locally that are affected by climate change or otherwise in need. Start a food or clothing drive and donate to organizations that are supporting such communities!

                </p>
            </div>
        </div>
    );
};

export default AboutClimate;
