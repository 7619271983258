import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { db } from '../firebase';
import loader from '../assets/images/icons8-dots-loading.gif'
import { formatWithMonthName } from "./utils"
import { getAuth } from "firebase/auth";

const MyDriveList = () => {
    // const { searchData } = useParams();
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResult, setSearchResult] = useState(false);

    useEffect(() => {
        // Fetch data from Firestore
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true)
        const auth = getAuth();
        const uId = auth.currentUser.uid;
        if (uId.length > 0) {
            const snapshot = await db.collection('driveData')
                .where('userId', '==', uId)
                .where('isActive', '==', true)
                .orderBy('createdOn', 'desc')
                .get();
            const data = snapshot.docs.map((doc) => doc.data());
            //console.log(data);
            setDataList(data);
        }

        setLoading(false)
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const clearForm = (event) => {
        setSearchTerm("");
        setSearchResult(false)
        fetchData();
    }

    const submitSearch = async (event) => {
        event.preventDefault(); // Prevent default form submission
        if (searchTerm.length > 0) {
            setLoading(true)
            setSearchResult(true)
            try {
                const auth = getAuth();
                const uId = auth.currentUser.uid;
                const snapshot = await db
                    .collection('driveData')
                    .where('isActive', '==', true)
                    .where('userId', '==', uId)
                    .where("searchData", "array-contains", searchTerm.trim())
                    // .where('driveName', '==', searchTerm)                    
                    .get();

                const data = snapshot.docs.map((doc) => doc.data());
                setDataList(data);
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.error('Error searching items:', error);
            }
        }

    };


    const handleDelete = async (driveId, index, event) => {
        event.preventDefault();
        if (window.confirm('Are you sure you want to delete?')) {
            console.log('delete');
            await db.collection("driveData").doc(driveId).update({
                isActive: false,
            });
            const newList = [...dataList]; // Create a copy of the list array
            newList.splice(index, 1); // Remove the item at the specified index
            setDataList(newList); // Update the state with the new list
        }
    };


    const loader_img = <img className="img-fluid" src={`${loader}`} alt="loader" />

    return (
        <div className="container py-5 container_box">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <form onSubmit={submitSearch}>
                        <div className="form-group">
                            <label htmlFor="name">Search Event</label>
                            <input
                                type="text"
                                className="form-control"
                                id="searchTerm"
                                autoComplete="off"
                                value={searchTerm}
                                onChange={handleSearch}
                                required
                            />
                        </div>

                        <div className="text-center mt-3">
                            <button type="submit" className="btn btn-primary ">
                                Search Event
                            </button>
                            <button onClick={clearForm} type="reset" className="btn btn-secondary margin-left-20">Reset</button>

                        </div>
                    </form>
                </div>
            </div>
            <br />
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <small className="form-text text-muted float-right">
                        <Link to="/drive-list">All Drives</Link>
                    </small>

                    <small className="form-text text-muted float-right">
                      <span><Link className='' to="/drives-booking">Create an Event</Link> &nbsp; | &nbsp; </span>
                    </small>

                    {searchResult === false ? (
                        <h2>My Drives</h2>
                    ) :
                        (<h2>Search For: {searchTerm}</h2>)
                    }

                </div>
            </div>
            <br />
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="card">
                        <div className="card-body table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Created On</th>
                                        <th>Event Name</th>
                                        <th>Event Purpose</th>
                                        <th>Event Location</th>
                                        <th>Event Description</th>
                                        {/* <th>Event dateTime</th> */}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList && dataList.length > 0 ? (
                                        dataList.map((dataItem, index) => (
                                            <tr key={index}>
                                                <td>{formatWithMonthName(dataItem.createdOn)}</td>
                                                <td>{dataItem.driveName}</td>
                                                <td>{dataItem.drivePurpose}</td>
                                                <td>{dataItem.driveLocation}</td>
                                                <td>{dataItem.driveDescription}</td>
                                                {/* <td>
                                                    {dataItem.dateTimeList && dataItem.dateTimeList.map((dateTime, index) => (
                                                        <span>{dateTime.date} &nbsp; {dateTime.time} &nbsp; &nbsp;</span>
                                                    ))}
                                                </td> */}
                                                <td width="115px">
                                                    <Link to={`/drive-detail/${dataItem.driveId}`} >
                                                        <i className="bi bi-eye-fill"></i>
                                                    </Link> &nbsp; | &nbsp;
                                                    <Link to={`/drives-booking/${dataItem.driveId}`} >
                                                        <i className="bi bi-pencil-fill"></i>
                                                    </Link>
                                                    &nbsp; | &nbsp;
                                                    <Link onClick={(event) => handleDelete(dataItem.driveId, index, event)} to="#" >
                                                        <i className="bi bi-trash-fill"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className="text-center border-bottom-width">
                                                <div className='text-center mt-3'>{loading ? loader_img : 'You haven\'t created any events!'}</div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default MyDriveList;
