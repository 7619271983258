import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { useNavigate, useParams, Link } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import { db } from '../firebase';

const DriveForm = () => {
    const { tblId } = useParams();
    const [state, setState] = useState({
        name: "", purpose: "", location: "",
        description: "", subtitle: "", selectedFile: "",
        orgName: "", affName: "", contactInfo: ""
    })
    const [Message, setMessage] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [dateTimeList, setDateTimeList] = useState([{ date: '', startTime: '', endTime: '' }]); // Initial input dateTimeList
    //const [dateTimeList, setDateTimeList] = useState([]);

    const [isUpdate, setIsUpdate] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (typeof tblId !== 'undefined' && tblId.length > 0) {
            // Fetch data from Firestore
            const fetchData = async () => {
                setLoading(true)
                const snapshot = await db.collection('driveData')
                    .where('driveId', '==', tblId)
                    .where('isActive', '==', true)
                    .get();
                const data = snapshot.docs.map((doc) => doc.data());
                //console.log(data);
                if (data.length > 0) {
                    setIsUpdate(true)
                    const fetchedData = {
                        name: data[0]['driveName'],
                        subtitle: data[0]['driveSubtitle'],
                        location: data[0]['driveLocation'],
                        purpose: data[0]['drivePurpose'],
                        description: data[0]['driveDescription'],
                        orgName: data[0]['organizationName'],
                        affName: data[0]['affliationName'],
                        contactInfo: data[0]['contactInfo']
                    };

                    setState({ ...state, ...fetchedData });//Set fields data

                    //const processedData = [];
                    if (data[0]['dateTimeList'].length > 0) {
                        setDateTimeList(data[0]['dateTimeList']); //Set Add more Datetime 
                    }

                    setLoading(false)
                } else {
                    setIsUpdate(false)
                    setLoading(false)
                }


            };

            fetchData();
        }


    }, [tblId]);


    const handleAddField = () => {
        setDateTimeList([...dateTimeList, { date: '', startTime: '', endTime: '' }]); // Add a new empty input field
    };

    const handleRemoveField = (index) => {
        const updatedFields = [...dateTimeList];
        updatedFields.splice(index, 1); // Remove the field at the specified index
        setDateTimeList(updatedFields);
    };

    const handleInputChange = (index, field, value) => {
        const updatedFields = [...dateTimeList];
        updatedFields[index][field] = value;
        setDateTimeList(updatedFields);
    };

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const auth = getAuth();
        //setIsButtonDisabled(true)
        const customId = uuid();//Math.random().toString(36).substring(2, 20);
        //console.log(auth);
        //return true;
        if (auth.currentUser) {
            try {
                const searchString = state.name + ' ' + state.subtitle + ' ' + state.purpose + ' ' + state.description + ' ' + state.location;
                const removeSpecialChar = searchString.replace(/[^\w\s]/g, '')
                //console.log(removeSpecialChar);return false;
                const searchArray = removeSpecialChar.split(' ').map(item => item.trim()).filter(item => item !== '');

                let formData = {
                    driveName: state.name,
                    driveSubtitle: state.subtitle,
                    drivePurpose: state.purpose,
                    driveLocation: state.location,
                    driveDescription: state.description,
                    organizationName: state.orgName,
                    affliationName: state.affName,
                    contactInfo: state.contactInfo,
                    dateTimeList: dateTimeList,
                    searchData: searchArray,
                    updatedOn: Date.now()
                }

                if (isUpdate === true) {
                    await db.collection("driveData").doc(tblId).update(formData);
                    setMessage('Data Updated succesfully!');
                    navigate('/my-drives')
                } else {
                    //console.log("formData==",formData);
                    let additanalData = {
                        isActive: true,
                        userId: auth.currentUser.uid,
                        driveId: customId,
                        createdOn: Date.now(),
                    }

                    let finalData = {
                        ...formData,
                        ...additanalData
                    };                    
                    //console.log("finalData==", finalData);
                    //return false;
                    await db.collection("driveData").doc(customId).set(finalData);
                    setState({
                        name: "", purpose: "", location: "", description: "",
                        date: "", time: "", subtitle: "",
                        orgName: "", affName: "", contactInfo: ""
                    });
                    setMessage('Data submitted succesfully!');
                    navigate('/my-drives')
                }

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        } else {
            navigate('/signin')
        }
        setIsButtonDisabled(false)
    };

    return (
        <div className="container py-5 container_box">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <small className="form-text text-muted float-right">
                        <Link to="/drive-list">All Drives</Link>
                    </small>
                </div>
            </div>
            <div className="row justify-content-center mt-2">

                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h2>Event Information</h2>
                            <form onSubmit={handleSubmit} >
                                <div className="form-group">
                                    <label htmlFor="name">Event Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        autoComplete="off"
                                        value={state.name}
                                        onChange={(e) => setState({ ...state, name: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="subtitle">Event Sub Title</label>
                                    <textarea
                                        className="form-control"
                                        id="subtitle"
                                        autoComplete="off"
                                        rows="2"
                                        value={state.subtitle}
                                        onChange={(e) => setState({ ...state, subtitle: e.target.value })}
                                        required
                                    ></textarea>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="purpose">Purpose</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="purpose"
                                        autoComplete="off"
                                        value={state.purpose}
                                        onChange={(e) => setState({ ...state, purpose: e.target.value })}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="location">Location</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="location"
                                        autoComplete="off"
                                        value={state.location}
                                        onChange={(e) => setState({ ...state, location: e.target.value })}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <textarea
                                        className="form-control"
                                        id="description"
                                        autoComplete="off"
                                        rows="5"
                                        value={state.description}
                                        onChange={(e) => setState({ ...state, description: e.target.value })}
                                        required
                                    ></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="name">Date/Time</label>
                                    {dateTimeList.map((field, index) => (
                                        <div className="row mt-3" key={index}>
                                            <div className="col-md-4">{index === 0 &&
                                                <label htmlFor="startTime">Date</label>
                                            }
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="date"
                                                    value={field.date}
                                                    onChange={(e) => handleInputChange(index, 'date', e.target.value)}                                                    
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                {index === 0 &&
                                                    <label htmlFor="startTime">Start Time</label>
                                                }
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    id="startTime"
                                                    value={field.startTime} //startTime: '',  endTime: ''
                                                    onChange={(e) => handleInputChange(index, 'startTime', e.target.value)}                                                    
                                                />
                                            </div>
                                            <div className="col-md-3">{
                                                index === 0 &&
                                                <label htmlFor="endTime">End Time</label>
                                            }

                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    id="endTime"
                                                    value={field.endTime} //startTime: '',  endTime: ''
                                                    onChange={(e) => handleInputChange(index, 'endTime', e.target.value)}
                                                    
                                                />
                                            </div>
                                            {index >= 1 && ( // Check if it is not the last set of input dateTimeList
                                                <div className="col-md-2 mt-1" >
                                                    <button className="btn-sm" type="button" variant="danger" onClick={() => handleRemoveField(index)}>
                                                        Remove
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    {dateTimeList.length < 5 &&
                                        <div className="mt-3">
                                            <button onClick={handleAddField} type="button" className="btn btn-primary pull-right">
                                                <i className="bi bi-plus-circle"></i>
                                            </button>
                                        </div>
                                    }
                                </div>


                                <div className="form-group mt-3">
                                    <label htmlFor="orgName">Organization Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="orgName"
                                        autoComplete="off"
                                        value={state.orgName}
                                        onChange={(e) => setState({ ...state, orgName: e.target.value })}
                                    />
                                </div>


                                <div className="form-group">
                                    <label htmlFor="affName">Affliation Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="affName"
                                        autoComplete="off"
                                        value={state.affName}
                                        onChange={(e) => setState({ ...state, affName: e.target.value })}
                                    />
                                </div>


                                <div className="form-group">
                                    <label htmlFor="contactInfo">Contact Info</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="contactInfo"
                                        autoComplete="off"
                                        value={state.contactInfo}
                                        onChange={(e) => setState({ ...state, contactInfo: e.target.value })}
                                    />
                                </div>

                                {/* <div className="form-group">
                                    <label htmlFor="imageUpload">Upload Image</label>
                                    <br />
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        id="imageUpload"
                                        accept="image/*"
                                        onChange={(e) => setState({ ...state, selectedFile: e.target.files[0] })}
                                    />
                                </div> */}

                                <div className="text-center mt-3">
                                    {isUpdate === true ? (
                                        <button disabled={isButtonDisabled} type="submit" className="btn btn-primary ">
                                            {loading ? 'Please Wait...' : 'Update'}
                                        </button>
                                    ) : (
                                        <button disabled={isButtonDisabled} type="submit" className="btn btn-primary ">
                                            Submit
                                        </button>
                                    )}

                                </div>
                            </form>
                            {Message && <p className="text-center text-success mt-3">{Message}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DriveForm;
