import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();
  return (
    <footer className="footer mt-3">
      <div className="container text-center">
      <p>&copy; {currentYear} Charity Drives. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
