import React, { useState, useEffect } from 'react';
import firebase from '../firebase';
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { useNavigate, Link, useLocation } from "react-router-dom";
import HomePage from './homePage';

const AuthForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLogin, setIsLogin] = useState(false);
  const [reCaptcha, setRecaptcha] = useState(false);
  const [reCaptchaErr, setRecaptchaErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(true); // State to track the selected action (sign-up or sign-in)

  const location = useLocation();
  //console.log(location.pathname);

  useEffect(() => {

    if (location.pathname === '/login') {
      setIsSigningUp(false);
    }
    //const user = firebase.auth().currentUser;
    const auth = getAuth();
    if (auth.currentUser) {
      setIsLogin(true)
    }

    if (auth.currentUser === null) {
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'visible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //this.onSignInSubmit();
          setRecaptcha(true)
          setRecaptchaErr(false)
          //console.log(response);
        }
      }, auth);

      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      }).catch((error) => {
        console.log(error);
      });
    }

    //console.log(auth);
  }, [location]);


  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setLoading(true);

    const appVerifier = window.recaptchaVerifier;
    if (isSigningUp) {

      if (reCaptcha === false) {
        setLoading(false);
        setErrorMessage('Solve the reCAPTCHA.');
        return
      };

      // Perform sign-up action

      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password, appVerifier)
        .then((userCredential) => {
          window.confirmationResult = userCredential;
          userCredential.user.updateProfile({ emailVerified: true })
            .then(() => {

              firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then((userCredential) => {
                  // Perform any actions after successful sign-in                
                  navigate('/')
                  setIsLogin(true);
                })
              setLoading(false);
            })
            .catch((error) => {
              // Handle error sending email verification
              if (error.code === 'auth/email-already-in-use') {
                setErrorMessage('You are already registered. Please sign in!');
              } else {
                setErrorMessage('Sending email verification failed, please try again!');
              }
              setErrorMessage('Sending email verification failed, please try again!');
              console.error('Error sending email verification:', error);
              setLoading(false);
              // Perform any error handling (e.g., show error message)
            });
        })
        .catch((error) => {
          // Handle error creating user
          if (error.code === 'auth/email-already-in-use') {
            setErrorMessage('You are already registered. Please sign in!');
          } else {
            setErrorMessage('Something went wrong!');
          }
          setLoading(false);
          // Perform any error handling (e.g., show error message)
        });

    } else {
      if (reCaptcha === false) {
        setErrorMessage('Solve the reCAPTCHA.');
        return
      };
      // Perform sign-in action
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Perform actions after successful sign-in 
          setIsLogin(true);
          setLoading(false);
          navigate('/');

        })
        .catch((error) => {
          // Handle sign-in error
          if (error.code === 'auth/wrong-password') {
            setErrorMessage('Incorrect password. Please try again.');
          } else if (error.code === 'auth/user-not-found') {
            setErrorMessage('You are not registered. Please sign up!');
          } else {
            setErrorMessage('Something went wrong!');
          }
          console.error('Error signing in:', error.code);
          setLoading(false);
          //setErrorMessage('You are not registered. Please sign up!');
          // Perform any error handling (e.g., show error message)
        });
    }

  };

  if (isLogin) {
    console.log(isLogin);
    return (
      <HomePage />
    )
  } else {

    return (
      <div className="container py-5 container_box">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h2 className="card-title text-center">
                  {isSigningUp ? (
                    <span>Sign Up</span>
                  ) : (
                    <span>Sign In</span>
                  )
                  }

                </h2>
                <form onSubmit={handleFormSubmit}>
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" autoComplete="off" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input minLength={6} type="password" autoComplete="off" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    {!isSigningUp &&
                      <small className="form-text text-muted float-right">
                        <Link to="/forgot-password">Forgot Password</Link>
                      </small>
                    }
                  </div>
                  <div className="form-group mt-3">
                    <div id="recaptcha-container"></div>

                    {reCaptchaErr && (
                      <div className="invalid-feedback">
                        Solve the reCAPTCHA.
                      </div>
                    )}
                  </div>
                  <div className="text-center mt-3">
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                      {loading ? 'Please Wait...' : isSigningUp ? 'Sign Up' : 'Sign In'}
                    </button>
                  </div>
                </form>
                <div className="text-center mt-3">
                  {isSigningUp ? (
                    <p>
                      Already have an account?{' '}
                      <a className='text-primary cursor-pointer' href="/login">Sign In</a>
                      {/* <span
                        className="text-primary cursor-pointer"
                        onClick={() => setIsSigningUp(false)}
                      >
                        Sign In
                      </span> */}
                    </p>
                  ) : (
                    <p>
                      Don't have an account?{' '}
                      <a className='text-primary cursor-pointer' href="/sign-up">Sign Up</a>
                      {/* <span
                        className="text-primary cursor-pointer"
                        onClick={() => setIsSigningUp(true)}
                      >
                        Sign Up
                      </span> */}
                    </p>
                  )}
                </div>
                {errorMessage && <p className="text-center text-danger mt-3">{errorMessage}</p>}

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthForm;
