import React from 'react';

const WhyDonate = () => {

    return (
        <div className="container py-5 container_box">
            <div className="row justify-content-center">
                <h2 className='text-center'>Why Donate?</h2>
                <p>In society, there is often a disparity or unequal distribution of resources, opportunities, wealth, power, or social status among individuals or groups within a society. This is called social inequality or social stratification, of which there are many different types, notably economic and social inequality.</p>

                <p>
                    Economic inequality refers to the unequal distribution of wealth, income, and resources. It typically refers to the wealth/income gap between the rich and the poor, where some individuals or groups have significantly more economic resources and opportunities than others. Such inequality can be quite extreme; in 2015, the 62 richest people in the world had as much money as the whole poorer half of the world combined, and the top 1% richest people in the world are richer than the rest of the world combined.
                </p>

                <p>
                    Social inequality refers to disparities in social factors such as education, healthcare, housing, and access to basic services or resources. Social inequality can be influenced by factors such as sexism, racism, ethnicity, age, disability, and other biases towards certain aspects of identity that lead to certain groups being marginalized more than others. This affects their quality of life, income, education level, access to food and water, and more.

                </p>
                <p>
                    Inequality can have significant consequences for individuals and societies. It can create social divisions, limit opportunities for upward mobility, and hinder economic growth. Addressing inequality often involves implementing policies and initiatives that aim to promote equal opportunities, reduce disparities, and ensure fair distribution of resources and benefits within a society.

                </p>
                <p>
                    Inequality also manifests in the form of homelessness. Homelessness in America is a crisis that speaks to the soul of our society. The National Alliance to End Homelessness reports that on any single night, over half a million people are experiencing homelessness. These aren't faceless statistics; these are our neighbors, friends, veterans who have served our nation, families with children, struggling in circumstances often beyond their control. Their stories are woven into the fabric of our cities and towns, a stark reminder of the inequity in our midst. A home is more than just a roof over our heads – it's a place of safety, of security, of dignity. We must not lose sight of this truth, for in recognizing their struggle, we recognize our responsibility to each other.
                </p>
                <p>
                    Confronting this inequality isn't a task reserved solely for lawmakers or philanthropists – it's a calling we all share. We can take daily actions that challenge the status quo and drive change. Leverage the power of your vote, supporting policies and politicians who prioritize social equity. Seek out knowledge and confront biases, understanding that education is a vital step towards change. If you hold a position of power or influence in the workplace, ensure equal opportunities and fair pay. And as important as all of the above is to donate to local charities and organizations that work to support marginalized groups. Amplify the voices of those who often go unheard, and remember that every act of kindness, every choice for justice, brings us one step closer to a more equitable world.
                </p>
            </div>
        </div>
    );
};

export default WhyDonate;
