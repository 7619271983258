import React from 'react';

const OrganizeADrive = () => {

    return (
        <div className="container py-5 container_box">
            <div className="row justify-content-center">
                <h2 className='text-center'>How to Organize a Drive?</h2>
                <p>Organizing a charity drive can be a rewarding yet complex process. Below are the steps that you might consider when planning such an event:
                </p>
                <p>
                    <ol>
                        <li>Identify the Need and Set Clear Objectives:
                            <ul>
                                <li>Define what items you will be collecting (food, clothes, books, etc.).</li>
                                <li>Determine who the beneficiaries(like a local charity) will be and how many people you aim to support.</li>
                                <li>Establish a target for the number or volume of items to collect.</li>
                            </ul>
                        </li>

                        <li>Assemble a Team:
                            <ul>
                                <li>Find enthusiastic and dedicated individuals who can help. They could be friends, family members, or colleagues.</li>
                                <li>Clearly assign roles and tasks. You'll need people for promotion, logistics, collection, and distribution.
                                </li>

                            </ul>
                        </li>

                        <li>Plan the Logistics:
                            <ul>
                                <li>
                                    Decide on the start and end dates of your charity drive.

                                </li>
                                <li>
                                    Secure drop-off locations for donations. Schools, community centers, or local businesses often offer space.
                                </li>
                                <li>
                                    Arrange for storage of collected items(such as large bins). If space is needed, consider reaching out to local storage companies that might donate or offer discounted space. Charities can sometimes loan their containers.
                                </li>
                                <li>
                                    Ensure you have sufficient transportation for moving collected items to the local charity.

                                </li>

                            </ul>
                        </li>

                        <li>Reach Out to Community Partners:

                            <ul>
                                <li>Contact local businesses for sponsorships or partnerships. They could help with funding, promotion, or logistics.</li>
                                <li>Connect with local charities who may be willing to work with you.</li>

                            </ul>
                        </li>


                        <li>Develop a Marketing Strategy:
                            <ul>
                                <li>Use free graphic design tools like <a target='_blank' href="https://www.canva.com/">Canva</a> to create promotional materials.</li>
                                <li>Create a page about your drive on this website (<a target='_blank' href="charitydrives.org">charitydrives.org</a>), nextdoor, evite etc, where you can provide details about the drive.
                                </li>
                                <li>Promote your event through social media channels (Facebook, Instagram, Twitter) and ask your network to help spread the word.</li>
                                <li>Write a press release and send it to local media outlets. Here's a <a target='_blank' href="https://fitsmallbusiness.com/press-release-examples/">guide</a> on how to write a press release.
                                </li>

                            </ul>
                        </li>



                        <li>Implement the Charity Drive:

                            <ul>
                                <li>Clearly mark your collection bins and make sure they're visible at the drop-off locations.
                                </li>
                                <li>Regularly check on the bins and move collected items to your storage area and sort if needed.
                                </li>

                            </ul>
                        </li>

                        <li>Post-event Actions:
                            <ul>
                                <li> Distribute collected items to your identified beneficiaries(likely a local charity).
                                </li>
                                <li>Reach out to donors and partners to thank them for their support. You can do this via a thank you email or social media post.</li>
                                <li>Share the results of your charity drive publicly (e.g., the number of items collected or number of people supported).</li>
                            </ul>
                        </li>

                    </ol>
                </p>
                <p>
                    Remember to take pictures during the collection and distribution process (ensure you have necessary permissions). These photos can be used to show the success of your drive and encourage participation in future events.

                </p>
            </div>
        </div>
    );
};

export default OrganizeADrive;
