import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
//import firebase from '../firebase';
//import DriveForm from './driveForm';
//import HeaderSlider from './carousel'
import { getAuth } from "firebase/auth";
//import video_bg from '../assets/videos/file.mp4'
import { Row, Col, Container, Card } from 'react-bootstrap';
import slide1 from '../assets/images/floods.jpeg'
import slide2 from '../assets/images/empathy_picture.png'
import slide3 from '../assets/images/burning_planet.jpeg'
import main_bg from '../assets/images/main_inequity_picture.jpeg'

const HomePage = () => {

    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        if (auth.currentUser) {
            setIsLogin(true)
        }
    }, []);

    return (
        <Container className='container_box container_box_home'>
            {/* <Row className="mb-3">
                <HeaderSlider />
            </Row> */}
            <div className="background-video embed-responsive embed-responsive-16by9">
                <img src={main_bg} class="video embed-responsive-item border-radius-12-12" alt="Charity Drives" />
                {/* <video width={"100%"} className="video embed-responsive-item border-radius-12" autoPlay muted loop>
                    <source src={video_bg} type="video/mp4" />
                  
                </video> */}

                <div className="overlay">
                    <div className='card_view card_view_home'>
                        <Row>
                            <Col md={4} className="mt-3">

                                <Card className='h-100'>
                                    <Card.Body>
                                        <Card.Title>Search for a Charity Event</Card.Title>
                                        <Card.Text className='text-color'>
                                            Want to support people in need of resources? Have clothes or extra food in your pantry you'd like to donate? Find material drives or charity events near you for whatever items you would like to donate.
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className='text-center'>
                                        {/* {!isLogin &&
                                        <a className='text-decoration button-link' href="/" >Charity Events</a>
                                    } */}

                                        <Link className='text-decoration button-link' to="/drive-list">Charity Events</Link>

                                    </Card.Footer>
                                </Card>

                            </Col>

                            <Col md={4} className="mt-3">

                                <Card className='h-100'>
                                    <Card.Body>
                                        <Card.Title>Start a Charity Event</Card.Title>
                                        <Card.Text className='text-color'>
                                            Starting a charity event or material drive can help provide basic resources to many who lack them for days to months. Click here to create an account and learn how to organize a drive and post it on this platform.

                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className='text-center'>
                                        {!isLogin &&
                                            <a className='text-decoration button-link' href="/login" >Create an Event</a>
                                        }
                                        {isLogin &&
                                            <Link className='text-decoration button-link' to="/drives-booking">Create an Event</Link>
                                        }
                                    </Card.Footer>
                                </Card>

                            </Col>
                            <Col md={4} className="mt-3">

                                <Card className='h-100'>
                                    <Card.Body>
                                        <Card.Title>Why Donate?</Card.Title>
                                        <Card.Text className='text-color'>
                                            In society, there is often a disparity or unequal distribution of resources, opportunities, wealth, power, or social status among individuals or groups within a society called social inequality or social stratification. Donating to charities or local drives is one significant way of many to help mitigate those discriminated against. Click here to find out more.

                                        </Card.Text>
                                    </Card.Body>

                                    <Card.Footer className='text-center'>
                                        <Link className='text-decoration button-link' to="/why-donate">Why Donate?</Link>

                                    </Card.Footer>
                                </Card>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <Row className="image-row mt-2 footer-boxes">
                <Col className='padding-bottom-10 '>
                    <div class="card h-100 justify-content-center">
                        <img src={slide1} class="img-fluid" alt="Flood" />
                    </div>


                </Col>
                <Col className='padding-bottom-10 '>
                    <div class="card h-100 justify-content-center slide2">
                        <img src={slide2} class="img-fluid border-radius-12" alt="Empathy" />
                    </div>

                </Col>
                <Col className='padding-bottom-10 '>

                    <div class="card h-100 justify-content-center">
                        <img src={slide3} class="img-fluid " alt="Burning Planet" />
                    </div>

                </Col>
            </Row>

        </Container>

    );

};

export default HomePage;
