export  function formatAMPM (date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let months = date.getMonth() + 1;
    let dates = date.getDate();
    let fullYrs = date.getFullYear();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, '0');
    let strTime = hours + ':' + minutes + ' ' + ampm + ' ' + months + '/' + dates + '/' + fullYrs;
    return strTime;
  }


  export  function formatWithMonthName (created_data) {
    const date = new Date(created_data);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    //let months = date.getMonth() + 1;
    let monthsName = date.toLocaleString('default', {month: 'short'});    
    let dates = date.getDate();
    let fullYrs = date.getFullYear();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, '0');
    let strTime = dates + ' ' + monthsName + ', '+ fullYrs + ' '+ hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  export  function formatWithMonthNameOnly (created_data) {
    const date = new Date(created_data);   
    //let months = date.getMonth() + 1;
    let monthsName = date.toLocaleString('default', {month: 'short'});    
    let dates = date.getDate();
    let fullYrs = date.getFullYear();   
    let strTime = dates + ' ' + monthsName + ', '+ fullYrs;
    return strTime;
  }

     
   export function kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num).toFixed(2)
}

export function convertUTCDateToLocalDate(date) {

  var newDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
  //console.log(newDate);
  return newDate;
  
}