import React from 'react';

const FoodInsecurity = () => {

    return (
        <div className="container py-5 container_box">
            <div className="row justify-content-center">
                <h2 className='text-center'>Why start a Food Drive?</h2>
                <p>Food insecurity is a serious global problem and is a way to measure how many people cannot afford to buy food. Food insecurity is not just a statistic - it's a lived experience for nearly 690 million people worldwide. It's the gnawing pain of hunger in a child's stomach, the desperation of a parent unable to provide, and the uncertainty of not knowing where the next meal will come from. Access to food is a basic fundamental human right and we must ensure access to safe, nutritious and sufficient food for all. </p>

                <p>
                    The stark realities of food insecurity are illuminated by sobering global statistics and examples. After steadily declining for a decade, world hunger is on the rise, affecting nearly 10% of people globally. According to the United Nations' Food and Agriculture Organization(FAO), as many as 828 million people were affected by hunger in 2022. From 2019 to 2022, the number of undernourished people grew by as many as 150 million, a crisis driven largely by conflict, climate change, and the COVID-19 pandemic. The FAO estimates that there are 2.3 billion people facing less extreme, but still dangerous, levels of food insecurity. That’s roughly 29% of the global population. More than 9 million people die from hunger every year even though access to food is a very basic and fundamental human right. In the United States, according to the USDA, more than 33 million people, including 5 million children, experience food insecurity. These figures underscore the enormity of the problem we face.
                </p>
                <p>
                    Climate change is intricately linked to food insecurity, which is both directly and indirectly escalating food insecurity across the globe. Climate change-induced events like extreme temperatures, droughts, floods, wildfires, etc. affect agricultural productivity negatively, leading to increasing food costs and food insecurity. Droughts have become more frequent and severe in regions like Sub-Saharan Africa, resulting in reduced farmland productivity and increased food scarcity. In South Asia, countries such as Bangladesh are grappling with extreme flooding events, leading to crop destruction and freshwater contamination. Central America's "Dry Corridor" is experiencing increased dry spells and erratic rainfall patterns, causing repeated crop failures and threatening the livelihoods of millions. Pacific Island nations are seeing rising sea levels and increased salinity, negatively impacting local agriculture, while heatwaves in Europe have significantly decreased agricultural output. Such harsh extreme weather events disproportionately affect poor people in low-income communities as they struggle to get food, clothing, supplies, clean water and shelter.

                </p>
                <p>
                    Despite these daunting challenges, there are many ways society can act to mitigate food insecurity. Grassroot initiatives such as organizing community food drives or establishing community gardens can contribute to local food availability. Schools, churches, and community centers can initiate feeding programs to support children and families experiencing food insecurity. At the individual level, conscious efforts to reduce food waste can also make a difference, considering the FAO estimates that about a third of the food produced globally is lost or wasted. In the United States, more than 100 billion pounds of food is wasted every year in the US (about 40% of all food produced in the US). That equates to about 130 billion
                    meals thrown away. About 40% of this wastage comes from our homes.

                </p>
                <p>
                    Food insecurity is a multi-faceted issue intertwined with many aspects of society and environment. While the statistics are alarming, proactive actions at individual, community, and policy levels can help us mitigate its impact and ensure a world where everyone has access to safe, nutritious, and sufficient food. Organizing a food drive is a step in this direction and will help to alleviate hunger for at least some people!

                </p>
            </div>
        </div>
    );
};

export default FoodInsecurity;
