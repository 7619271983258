import React, { useState } from 'react';
import firebase from '../firebase';
import { Card, Form, Button, Alert } from 'react-bootstrap';

//import 'firebase/auth';
//import { getAuth } from "firebase/auth";

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [resetSent, setResetSent] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (email.length > 0) {
            setLoading(true);
            try {
                await firebase.auth().sendPasswordResetEmail(email);
                setResetSent(true);
                setLoading(false);
            } catch (error) {
                if (error.code === 'auth/user-not-found') {
                    setError('You are not registered. Please sign up!');
                } else {
                    setError('Something went wrong!');
                }
                setLoading(false);
                //setError(error.message);
            }
        } else {
            setError('Please Enter Email!')
        }
    }

    // Hide the error after 3 seconds
    setTimeout(() => {
        setError(false)
    }, 5000);


return (
    <div className="container py-5 container_box">
        <div className="row justify-content-center">
            <div className="col-md-6 ">
                <Card>
                    <Card.Body>
                        <Card.Title>Forgot Password</Card.Title>
                        {resetSent ? (
                            <Alert variant="success">Password reset email has been sent to your email address.</Alert>
                        ) : (
                            <Form onSubmit={handleResetPassword}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email:</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        autoComplete="off"
                                    />
                                </Form.Group>
                                <Button disabled={loading} variant="primary" type="submit" className='mt-2'>
                                    {loading ? 'Please Wait...' : 'Reset Password'}
                                </Button>
                                {error && <Alert className='mt-2' variant="danger">{error}</Alert>}
                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </div>
        </div>

    </div>
);
};

export default ForgotPassword;
